<template>
  <div class="chats">
    <div
      v-for="(message, index) in chatConversation"
      :key="message.sender_id + String(index)"
      class="chat"
      :class="{ 'chat-left': message.sender_id == chatData.user_id }"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="chatData.profile_picture_url"
        />
      </div>
      <div class="chat-body">
        <div
          v-if="message.attachment_type == 'image'"
          class="d-flex chat-content"
          :class="{ 'justify-content-end': message.sender_id != chatData.user_id }"
        >
          <el-image
            fluid
            style="width: 100px;"
            :src="message.attachment_url"
            :preview-src-list="[message.attachment_url]"
          />
        </div>
        <div
          v-else-if="message.attachment_type == 'ig_reel'"
          class="d-flex chat-content"
          :class="{ 'justify-content-end': message.sender_id != chatData.user_id }"
        >
          <video
            width="400"
            controls
          >
            <source
              :src="message.attachment_url"
              type="video/mp4"
            >
            Your browser does not support HTML video.
          </video>
        </div>
        <div
          v-else-if="message.attachment_type == 'audio'"
          class="d-flex chat-content"
          :class="{ 'justify-content-end': message.sender_id != chatData.user_id }"
        >
          <audio controls>
            <source
              :src="message.attachment_url"
              type="audio/ogg"
            >
            <source
              :src="message.attachment_url"
              type="audio/mpeg"
            >
            Your browser does not support the audio element.
          </audio>
        </div>
        <div
          v-else
          class="chat-content"
        >
          <template v-if="message.story_url">
            <div v-if="true">
              <video
                width="400"
                controls
              >
                <source
                  :src="message.story_url"
                  type="video/mp4"
                >
                Your browser does not support HTML video.
              </video>
            </div>
            <div v-else>
              <el-image fluid style="width: 100px;" :src="message.story_url" :preview-src-list="[message.story_url]" />
            </div>
          </template>

          <p>{{ message.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    chatConversation: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      contentType: {},
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>

<style></style>
